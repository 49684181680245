import 'bootstrap.native';
import 'lazysizes';
import 'svgxuse';
import 'picturefill';
import 'nodelist-foreach-polyfill';
import ObjectAssign from 'es6-object-assign';
import 'string-includes-polyfill';
import 'polyfill-array-includes';
import smoothscroll from 'smoothscroll-polyfill';

import initResponsiveBackgroundImages from './utils/responsive-bg-image';
import initBodyClass from './utils/bodyClass';
import initModalExtensions from './utils/bootstrap-modals';
import { initResetableForms, initPreventEmptySubmitForms } from './utils/forms';
import initMainNavigation from './components/main-navigation';
import initCookieBar from './components/cookie-bar';
import initCenterServices from './components/center-services';
import highlightCurrentDays from './utils/day-highlight';
import initStoreFilter from './components/stores-filter';
import initLanguagePopup from './components/language-popup';
import initSentry from './components/sentry';
import initSmartPhoto from './components/smartphoto';
import initTableColWidth from './utils/table-col-width';

ObjectAssign.polyfill();
smoothscroll.polyfill();
initResponsiveBackgroundImages();
initBodyClass();
initSentry();
initModalExtensions();
initResetableForms();
initPreventEmptySubmitForms();
initMainNavigation();
initCookieBar();
initCenterServices();
highlightCurrentDays();
initStoreFilter();
initLanguagePopup();
initSmartPhoto();
initTableColWidth();
