export default function initModalExtensions() {
  document.querySelectorAll('.modal').forEach(modal => {
    modal.addEventListener('shown.bs.modal', e => {
      const input = e.target.querySelector('[autofocus]');
      if (input) {
        input.focus();
      }
    });
  });
}
