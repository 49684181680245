import { tns } from 'tiny-slider/src/tiny-slider';
import breakpoints from '../utils/breakpoints';

let sliders = [];
let timer;
let slidersMounted = false;

export default function initCenterServices() {
  const services = document.querySelectorAll('[data-center-service-slider="true"]');

  if (services.length) {
    services.forEach(item => {
      sliders.push(initSlider(item));
    });
    slidersMounted = true;
    handleSliderLifecycle();

    window.addEventListener('resize', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleSliderLifecycle();
      }, 100);
    });
  }
}

function handleSliderLifecycle() {
  let newSliders = [];

  if (showSliders() && !slidersMounted) {
    sliders.forEach(slider => {
      newSliders.push(slider.rebuild());
    });
    slidersMounted = true;
  } else if (!showSliders() && slidersMounted) {
    sliders.forEach(slider => {
      slider.destroy();
      newSliders.push(slider);
    });
    slidersMounted = false;
  } else {
    newSliders = sliders;
  }

  sliders = newSliders;
}

function showSliders() {
  return window.innerWidth < breakpoints.lg;
}

function initSlider(node) {
  return tns({
    container: node,
    items: 1,
    controls: false,
    navPosition: 'bottom',
    gutter: 15,
    preventScrollOnTouch: 'auto',
  });
}
