import SmartPhoto from 'smartphoto';

export default function initSmartPhoto() {
  const lightboxSelector = '.lightbox-image';
  const lightboxSelectorGallery = '.lightbox-gallery-image';
  const lightboxSelectorTextImage = '.lightbox-text-image';
  const lightboxSelectorTextImageHalf = '.lightbox-text-image-half';
  const lightboxSelectorTextImageHalfMobile = '.lightbox-text-image-half-mobile';

  const lightboxElements = document.querySelectorAll(lightboxSelector);
  const lightboxSelectorGalleryElements = document.querySelectorAll(lightboxSelectorGallery);
  const lightboxTextImageElements = document.querySelectorAll(lightboxSelectorTextImage);
  const lightboxTextImageHalfElements = document.querySelectorAll(lightboxSelectorTextImageHalf);
  const lightboxTextImageHalfMobile = document.querySelectorAll(lightboxSelectorTextImageHalfMobile);

  new SmartPhoto(lightboxElements);
  new SmartPhoto(lightboxSelectorGalleryElements);
  new SmartPhoto(lightboxTextImageElements);
  new SmartPhoto(lightboxTextImageHalfElements);
  new SmartPhoto(lightboxTextImageHalfMobile);
}
