const filterItems = document.querySelectorAll('.store-list-filter__item');
const itemsToFilter = document.querySelectorAll('.store-list__item');
const panelOpener = document.querySelectorAll('.scl-panel-title');
const hiddenClass = 'store-list-filter__item--hidden';
const activeClass = 'active';
const dataAttrLetter = 'data-letter';
const dataAttrCategory = 'data-category';
const letterFilterContainer = document.getElementById('headingStores');

function filter() {
  const letter = this.getAttribute(dataAttrLetter);
  const category = this.getAttribute(dataAttrCategory);

  filterItems.forEach(elements => {
    elements.classList.remove(activeClass);
  });

  this.classList.add(activeClass);

  itemsToFilter.forEach(elements => {
    elements.classList.add(hiddenClass);
    letterFilterContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });

    if (
      elements.getAttribute(dataAttrLetter).trim() === letter ||
      letter === '*' ||
      elements.getAttribute(dataAttrCategory).trim() === category ||
      category === '*'
    ) {
      elements.classList.remove(hiddenClass);
    }
  });
}

function resetFilterAndMark() {
  filterItems.forEach(elements => {
    elements.classList.remove(activeClass);
  });

  itemsToFilter.forEach(elements => {
    elements.classList.remove(hiddenClass);
  });
}
export default function initStoreFilter() {
  filterItems.forEach(elements => {
    elements.addEventListener('click', filter);
  });

  panelOpener.forEach(elements => {
    elements.addEventListener('click', resetFilterAndMark);
  });
}
