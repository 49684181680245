export default function initTableColWidth() {
  const openingHoursTable = document.querySelector('.scl-sidebar__openhours');

  if (openingHoursTable) {
    updateHolidaysTableColWidth();

    window.addEventListener('resize', () => {
      updateHolidaysTableColWidth();
    });
  }
}

function updateHolidaysTableColWidth() {
  setColWidth(getColWidth());
}

function getColWidth() {
  return document.querySelector('.scl-sidebar__openhours .text-left').offsetWidth;
}

function setColWidth(width) {
  document.querySelector('.scl-sidebar__openhoursholidays .text-left').style.width = `${width}px`;
}
