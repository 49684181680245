import breakpoints from '../utils/breakpoints';

export default function initMainNavigation() {
  const itemWithSubpagesClass = 'with-subpages';
  const navigationOpenClass = 'nav-open';
  const togglerSelector = '.hamburger';
  const togglerActiveClass = 'is-active';
  const dropdownClass = '.main-menu__dropdown';
  const dropdownActiveClass = 'main-menu__dropdown--active';
  const mainMenuNavigatedClass = 'main-menu--navigated';
  const defaultDropdownClass = '.dropdown';
  const dropdownSelector = '#navbarResponsive';
  const itemWithSubpagesSelector = `.main-menu__list > .${itemWithSubpagesClass}`;
  const itemMainSelector = '.main-menu__list--main > li';
  const dropdownActiveClassSelector = `.${dropdownActiveClass}`;
  const mainMenuSelector = '.main-menu';
  const menuCategorySelector = '.menu-category';
  const menuStoreSelector = '.menu-store';
  const filterEnablerSelector = '.enable-store-filter';
  const categories = document.querySelectorAll(menuCategorySelector);
  const selectedCategory = categories[0];
  const stores = document.querySelectorAll(menuStoreSelector);
  const mainMenu = document.querySelector(mainMenuSelector);
  const hasFilter = document.querySelector(filterEnablerSelector);
  const dropdown = document.querySelectorAll(defaultDropdownClass);
  const categoryDataAttribute = 'data-category';
  const burger = document.querySelector(togglerSelector);
  const menuHoverDelay = 200;

  if (!mainMenu) {
    return;
  }

  burger.addEventListener('click', () => {
    burger.classList.toggle(togglerActiveClass);
    document.querySelector(dropdownSelector).classList.toggle(togglerActiveClass);
    document.body.classList.toggle(navigationOpenClass);
    document.querySelector(mainMenuSelector).classList.remove(mainMenuNavigatedClass);
    document.querySelector(dropdownClass).classList.remove(dropdownActiveClass);
  });

  function filterStores(category) {
    categories.forEach(e => {
      e.classList.remove('active');
    });

    stores.forEach(e => {
      e.classList.remove('d-none');
    });

    stores.forEach(el => {
      let storeCategories = el.getAttribute(categoryDataAttribute).replace(/\s+/g, '');

      if (storeCategories) {
        storeCategories = storeCategories.split(',');
        if (!storeCategories.includes(category)) {
          el.classList.add('d-none');
        }
      } else {
        el.classList.add('d-none');
      }
    });
  }

  function showStoresOfCategory(e) {
    const category = e.target.getAttribute(categoryDataAttribute).replace(/\s+/g, '');
    filterStores(category);
    e.target.classList.add('active');
  }

  if (hasFilter) {
    categories.forEach(elements => {
      let timeout = null;

      elements.addEventListener('mouseenter', e => {
        timeout = setTimeout(() => showStoresOfCategory(e), menuHoverDelay);
      });

      elements.addEventListener('mouseleave', () => {
        clearTimeout(timeout);
      });
    });
  }

  function selectFirstCategory() {
    const firstCategory = selectedCategory.getAttribute(categoryDataAttribute).replace(/\s+/g, '');
    filterStores(firstCategory);
    selectedCategory.classList.add('active');
  }

  if (categories.length && hasFilter) {
    selectFirstCategory();
  }

  // navigation to submenu
  document.querySelectorAll(`${itemWithSubpagesSelector}> a`).forEach(el => {
    el.addEventListener('click', e => {
      if (isMobileMenuActive()) {
        e.preventDefault();
        const submenu = e.currentTarget.parentNode.querySelector('.main-menu__dropdown');
        if (submenu) {
          submenu.classList.toggle(dropdownActiveClass);
          mainMenu.classList.toggle(mainMenuNavigatedClass);
        }
      }
    });
  });

  // main menu hover delay
  document.querySelectorAll(itemWithSubpagesSelector).forEach(el => {
    let timeout = null;

    el.addEventListener('mouseenter', () => {
      if (!isMobileMenuActive()) {
        if (timeout) {
          clearTimeout(timeout);
        }
        el.classList.add('hover');
      }
    });

    el.addEventListener('mouseleave', () => {
      if (!isMobileMenuActive()) {
        timeout = setTimeout(() => {
          el.classList.remove('hover');
        }, menuHoverDelay);
      }
    });
  });

  // reset menu after closing
  document.querySelector(dropdownSelector).addEventListener('hide.bs.collapse', () => {
    const activeDropdown = document.querySelector(dropdownActiveClassSelector);
    if (activeDropdown) {
      activeDropdown.classList.remove(dropdownActiveClass);
    }
    mainMenu.classList.remove(mainMenuNavigatedClass);
  });

  document.querySelectorAll(itemMainSelector).forEach(el => {
    el.addEventListener('mouseenter', () => {
      closeLanguageDropdown();
    });
  });

  //  dropdown
  let defaultDropdownDelay = null;

  dropdown.forEach(item => {
    item.addEventListener('mouseenter', () => {
      if (defaultDropdownDelay) {
        clearTimeout(defaultDropdownDelay);
      }
      item.querySelector('.dropdown-menu').classList.add('show');
    });

    item.addEventListener('mouseleave', () => {
      defaultDropdownDelay = setTimeout(() => {
        item.querySelector('.dropdown-menu').classList.remove('show');
      }, menuHoverDelay);
    });
  });
}

function isMobileMenuActive() {
  return document.documentElement.clientWidth < breakpoints.xl;
}

function closeLanguageDropdown() {
  const dropdownElement = document.getElementById('dropdownMenuLink');
  if (dropdownElement.Dropdown) {
    if (dropdownElement.parentNode.classList.contains('show')) {
      dropdownElement.Dropdown.toggle();
    }
  }
}
