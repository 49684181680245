import Cookies from 'js-cookie';

const acceptedCookieName = 'scl-cookies-accepted';
const cookieBars = document.querySelectorAll('.scl-cookie-bar');

function shouldShowCookieBar() {
  return !Cookies.get(acceptedCookieName);
}

function acceptClicked() {
  Cookies.set(acceptedCookieName, 'true', { expires: 365 });

  cookieBars.forEach(cookieBar => {
    cookieBar.classList.remove('scl-cookie-bar--active');
  });
}

function handleBarState() {
  cookieBars.forEach(cookieBar => {
    const cookieBarButton = cookieBar.querySelector('.scl-cookie-bar-accept');

    if (shouldShowCookieBar()) {
      cookieBar.classList.add('scl-cookie-bar--active');
      cookieBarButton.addEventListener('click', e => {
        e.preventDefault();
        acceptClicked();
      });
    }
  });
}

export default function initCookieBar() {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
      handleBarState();
    });
  } else {
    handleBarState();
  }
}
