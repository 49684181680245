const inputSelector = '.form-control';
const resetSelector = '.scl-form-line__reset';
const submitSelector = '.scl-btn';

export function initResetableForms() {
  document.querySelectorAll('.scl-resetable-form-group').forEach(parent => {
    const input = parent.querySelector(inputSelector);
    const reset = parent.querySelector(resetSelector);
    const submit = parent.querySelector(submitSelector);

    if (input.value.length) {
      reset.style.display = 'block';
      submit.style.display = 'none';
    }

    reset.addEventListener('click', e => {
      e.preventDefault();
      input.value = '';
      input.focus();
      reset.style.display = 'none';
      submit.style.display = 'block';
    });

    input.addEventListener('keyup', () => {
      reset.style.display = 'none';
      submit.style.display = 'block';
    });
  });
}

export function initPreventEmptySubmitForms() {
  document.querySelectorAll('[scl-no-empty-submit-form]').forEach(form => {
    const inputs = form.querySelectorAll('[scl-no-empty-input]');

    form.addEventListener('submit', e => {
      inputs.forEach(input => {
        if (!input.value) {
          e.preventDefault();
          input.focus();
        }
      });
    });
  });
}
