import locale2 from 'locale2';
import Cookies from 'js-cookie';
import bs from 'bootstrap.native';

const modalSelector = '#scl-language-modal';
const modalElem = document.querySelector(modalSelector);

const cookieName = 'scl-language-chosen';
const browserLanguage = locale2.substr(0, 2);

function getPageLanguage() {
  const activeElem = modalElem.querySelector('[data-language-active="1"]');
  return activeElem ? activeElem.dataset.language : '';
}

function getBrowserLanguage() {
  return browserLanguage;
}

function isPopupInteracted() {
  return !!Cookies.get(cookieName);
}

function isLanguageAvailable() {
  const languageElems = modalElem.querySelectorAll('[data-language]');
  const languages = [];
  languageElems.forEach(elem => {
    if (elem.dataset.language) {
      languages.push(elem.dataset.language);
    }
  });

  return languages.includes(getBrowserLanguage());
}

function setInteracted() {
  Cookies.set(cookieName, 'true', { expires: 365 });
}

function shouldBePopupDisplayed() {
  return getBrowserLanguage() !== getPageLanguage() && !isPopupInteracted() && isLanguageAvailable();
}

export default function initLanguagePopup() {
  if (!modalElem) {
    return;
  }

  if (shouldBePopupDisplayed()) {
    const modal = new bs.Modal(modalElem, {
      backdrop: true,
    });

    const currentBody = modalElem.querySelector(`[data-language="${getBrowserLanguage()}"]`);
    currentBody.classList.remove('d-none');
    currentBody.querySelectorAll('.scl-btn').forEach(btn => {
      btn.addEventListener('click', () => {
        setInteracted();
      });
    });

    modalElem.addEventListener('hide.bs.modal', () => {
      setInteracted();
    });

    window.setTimeout(() => modal.show(), 5000);
  }
}
